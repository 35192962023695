<template>
  <v-container id="purchase" fluid tag="section">
    <v-snackbar v-model="editFlash" :timeout="4000" top>
      <span>Purchase Edit Successfully</span>
      <template v-slot:action="{ attrs }">
        <v-btn color="primary" text v-bind="attrs" @click="editFlash = false"
          >Close</v-btn
        >
      </template>
    </v-snackbar>
    <v-snackbar v-model="addFlash" :timeout="4000" top>
      <span>Purchase Add Successfully</span>
      <template v-slot:action="{ attrs }">
        <v-btn color="primary" text v-bind="attrs" @click="addFlash = false"
          >Close</v-btn
        >
      </template>
    </v-snackbar>
    <v-snackbar v-model="deleteFlash" :timeout="4000" top>
      <span>Purchase Delete Successfully</span>
      <template v-slot:action="{ attrs }">
        <v-btn color="primary" text v-bind="attrs" @click="deleteFlash = false"
          >Close</v-btn
        >
      </template>
    </v-snackbar>
    <v-row>
      <v-col>
        <v-radio-group v-model="selectedStore" row>
          <v-radio
            v-for="(store, index) in stores"
            :key="index"
            :label="store.name"
            :value="store"
            @click="handleStoreOutofStocks(store)"
          ></v-radio>
        </v-radio-group> </v-col
    ></v-row>
    <v-data-table
      :headers="columns"
      :items="outOfStocksData"
      item-key="_id"
      class="elevation-1"
      hide-default-footer
      disable-pagination
      :loading="loading"
      loading-text="Loading... Please wait"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-row>
            <v-col>
              <v-text-field
                @keyup="globalSearch"
                v-model="search"
                label="Search"
                class="mx-4 mt-4"
              ></v-text-field>
            </v-col>
            <!-- <v-col>
              <router-link
                v-if="modulePermission.Create"
                :to="{ name: 'Add Purchase' }"
              >
                <v-btn color="primary float-right" dark class="mt-4"
                  >Add Purchase</v-btn
                >
              </router-link>
            </v-col> -->
          </v-row>
        </v-toolbar>
      </template>

      <template v-slot:[`item._id`]="{ index }">
        {{ numbers[index] }}
      </template>
      <template v-slot:[`item.product_name`]="{ item }">
        {{ item.product_data[0].name }}
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <!-- <router-link
          v-if="modulePermission.Update"
          :to="{
            name: 'Edit Purchase',
            params: { id: item._id }
          }"
          ><v-icon class="mr-2">mdi-pencil</v-icon></router-link
        >
        <router-link
          v-if="modulePermission.Read"
          :to="{
            name: 'View Purchase',
            params: { id: item._id, action: 'view' }
          }"
          ><v-icon class="mr-2">mdi-eye</v-icon></router-link
        > -->
        <!-- <v-icon v-if="modulePermission.Delete" @click="deletePurchase(item)"
          >mdi-delete</v-icon
        > -->
      </template>
    </v-data-table>
    <div class="custom_pagination_design">
      <span>
        Showing <b>{{ startRecord }}</b> to <b>{{ endRecord }}</b> of
        <b>{{ totalRecord }}</b> Records
      </span>
      <v-pagination
        class="float-right"
        v-model="page"
        :length="totalPages"
        total-visible="7"
        next-icon="mdi-menu-right"
        prev-icon="mdi-menu-left"
        @input="handlePageChange"
      ></v-pagination>
    </div>
  </v-container>
</template>

<script>
export default {
  props: ["edit", "add"],
  data() {
    return {
      moduleName: "Out-Of-Stocks",
      modulePermission: [],
      editFlash: false,
      addFlash: false,
      deleteFlash: false,
      search: "",
      loading: true,
      outOfStocksData: [],
      page: 1,
      totalPages: 0,
      startRecord: 0,
      endRecord: 0,
      totalRecord: 0,
      dialogDelete: false,
      stores: [],
      selectedStore: null
    };
  },
  computed: {
    columns() {
      return [
        // {
        //   text: "Actions",
        //   value: "actions",
        //   fixed: true,
        //   sortable: false,
        //   width: "100px"
        // },
        {
          sortable: false,
          text: "Product Name",
          value: "name",
          width: "150px"
        },
        {
          sortable: false,
          text: "UPC",
          value: "product_variants[0].variant_upc_number",
          width: "180px"
        },
        {
          sortable: false,
          text: "SKU",
          value: "product_variants[0].variant_sku_number",
          width: "100px"
        }
      ];
    }
  },
  methods: {
    getOutOfStocksProduct() {
      if (this.search == "") {
        this.search = null;
      }
      axios
        .get(
          process.env.VUE_APP_API_BASE_URL +
            "outOfStock/products/" +
            this.selectedStore._id +
            "/" +
            this.search +
            "?page=" +
            this.page
        )
        .then(response => {
          if (response.status == 200) {
            this.outOfStocksData = response.data.data.data;
            this.loading = false;
            this.totalPages = response.data.data.last_page;
            this.startRecord = response.data.data.from;
            this.endRecord = response.data.data.to;
            this.totalRecord = response.data.data.total;
            this.numbers = [];
            for (
              let num = response.data.data.from;
              num <= response.data.data.to;
              num++
            ) {
              this.numbers.push(num);
            }
          }
        })
        .catch(error => {
          console.log(error);
        });
    },
    globalSearch() {
      this.loading = true;
      this.getOutOfStocksProduct();
    },
    handlePageChange(value) {
      this.loading = true;
      this.page = value;
      this.getOutOfStocksProduct();
    },
    getStores() {
      axios
        .get(process.env.VUE_APP_API_BASE_URL + "store/getall")
        .then(response => {
          if (response.status == 200) {
            this.stores = response.data.stores;
            this.selectedStore = this.stores[0];
            this.getOutOfStocksProduct();
          }
        })
        .catch(error => {
          console.log(error);
        });
    },

    handleStoreOutofStocks(store_id) {
      this.getOutOfStocksProduct();
    }
  },
  mounted() {
    this.getStores();
    let permissions = JSON.parse(sessionStorage.getItem("user-permission"));
    this.modulePermission = permissions[this.moduleName];
    if (this.edit) {
      this.editFlash = true;
    }
    if (this.add) {
      this.addFlash = true;
    }
  }
};
</script>
